var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('h1',[_vm._v("Comercios Cargo")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mx-8 mx-md-0",attrs:{"justify-md":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" Crear nuevo comercio ")],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.businesses,"search":_vm.$store.state.search,"items-per-page":15,"loading":_vm.loading,"sort-by":"createdAt","sort-desc":true,"item-key":".key","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:`item.imagen`,fn:function({ item }){return [(
              item.picture != 'assets/imgs/avatar-default.svg' && item.logo
            )?_c('v-avatar',{staticClass:"mt-3"},[_c('v-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.logo.original,"alt":item.name,"lazy-src":item.logo.loading}})],1):_vm._e()]}},{key:`item.email`,fn:function({ item }){return [(item.email)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block"},attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.email)}}},on),[_vm._v(" "+_vm._s(item.email)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])]):_vm._e()]}},{key:`item.phone`,fn:function({ item }){return [(item.phone)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block"},attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.phone)}}},on),[_vm._v(" "+_vm._s(item.phone)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])]):_vm._e()]}},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { active: item.active },
                item['.key'],
                item,
                'active'
              )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.canChangePoints`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { canChangePoints: item.canChangePoints },
                item['.key'],
                item,
                'canChangePoints'
              )}},model:{value:(item.canChangePoints),callback:function ($$v) {_vm.$set(item, "canChangePoints", $$v)},expression:"item.canChangePoints"}})]}},{key:`item.allowNotifications`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { allowNotifications: item.allowNotifications },
                item['.key'],
                item,
                'allowNotifications'
              )}},model:{value:(item.allowNotifications),callback:function ($$v) {_vm.$set(item, "allowNotifications", $$v)},expression:"item.allowNotifications"}})]}},{key:`item.canChangeStartingPoint`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { canChangeStartingPoint: item.canChangeStartingPoint },
                item['.key'],
                item,
                'canChangeStartingPoint'
              )}},model:{value:(item.canChangeStartingPoint),callback:function ($$v) {_vm.$set(item, "canChangeStartingPoint", $$v)},expression:"item.canChangeStartingPoint"}})]}},{key:`item.canChangeSecondaryPoints`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { canChangeSecondaryPoints: item.canChangeSecondaryPoints },
                item['.key'],
                item,
                'canChangeSecondaryPoints'
              )}},model:{value:(item.canChangeSecondaryPoints),callback:function ($$v) {_vm.$set(item, "canChangeSecondaryPoints", $$v)},expression:"item.canChangeSecondaryPoints"}})]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.createdAt))+" ")]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" Editar ")])]}}],null,true)})],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"max-width":"850","persistent":""},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('add',{on:{"success":_vm.handleSuccess,"cancel":function($event){_vm.addDialog = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }