<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="9">
        <h1>Comercios Cargo</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify-md="end" class="mx-8 mx-md-0">
          <v-btn
            @click="add()"
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Crear nuevo comercio
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="businesses"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="createdAt"
          :sort-desc="true"
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.imagen`]="{ item }">
            <v-avatar
              class="mt-3"
              v-if="
                item.picture != 'assets/imgs/avatar-default.svg' && item.logo
              "
            >
              <v-img
                style="object-fit: cover"
                :src="item.logo.original"
                :alt="item.name"
                :lazy-src="item.logo.loading"
              >
              </v-img>
            </v-avatar>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip v-if="item.email" left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.email)"
                >
                  {{ item.email }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <v-tooltip v-if="item.phone" left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.phone)"
                >
                  {{ item.phone }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item,
                  'active'
                )
              "
              v-model="item.active"
            />
          </template>

          <!-- <template v-slot:[`item.canChangeDestinationPoint`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { canChangeDestinationPoint: item.canChangeDestinationPoint },
                  item['.key'],
                  item,
                  'canChangeDestinationPoint'
                )
              "
              v-model="item.canChangeDestinationPoint"
            />
          </template> -->

          <template v-slot:[`item.canChangePoints`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { canChangePoints: item.canChangePoints },
                  item['.key'],
                  item,
                  'canChangePoints'
                )
              "
              v-model="item.canChangePoints"
            />
          </template>

          <template v-slot:[`item.allowNotifications`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { allowNotifications: item.allowNotifications },
                  item['.key'],
                  item,
                  'allowNotifications'
                )
              "
              v-model="item.allowNotifications"
            />
          </template>

          <template v-slot:[`item.canChangeStartingPoint`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { canChangeStartingPoint: item.canChangeStartingPoint },
                  item['.key'],
                  item,
                  'canChangeStartingPoint'
                )
              "
              v-model="item.canChangeStartingPoint"
            />
          </template>

          <template v-slot:[`item.canChangeSecondaryPoints`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { canChangeSecondaryPoints: item.canChangeSecondaryPoints },
                  item['.key'],
                  item,
                  'canChangeSecondaryPoints'
                )
              "
              v-model="item.canChangeSecondaryPoints"
            />
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="edit(item)" small color="primary"> Editar </v-btn>

            <!-- <v-btn small class="ml-3 white--text" color="red darken-4"
              >Eliminar</v-btn
            > -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog max-width="850" persistent v-model="addDialog" v-if="addDialog">
      <add @success="handleSuccess" @cancel="addDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState, mapActions } from "vuex";
import ledgerMixin from "@/mixins/ledgerMixin";
import add from "./add";
import lottieSettings from "@/mixins/lottieMixin";
import Lottie from "@/components/Lottie";

export default {
  mixins: [ledgerMixin, lottieSettings],
  components: {
    add,
    Lottie,
  },
  data() {
    return {
      loading: true,
      businesses: [],
      snackbar: false,
      snackbarText: "",
      addDialog: false,
      headers: [
        {
          value: "imagen",
        },
        {
          text: "Nombre",
          value: "shortName",
          width: "200px",
          class: "nowrap",
        },

        {
          text: "Teléfono",
          value: "phone",
          width: "100px",
          class: "nowrap",
        },

        {
          text: "Email",
          value: "email",
          width: "100px",
          class: "nowrap",
        },
        {
          text: "Dirección",
          value: "addressDescription",
          class: "nowrap",
          width: "300px",
        },
        {
          text: "¿Puede cambiar punto inicial?",
          value: "canChangeStartingPoint",
          class: "nowrap",
        },
        {
          text: "¿Puede cambiar puntos secundarios?",
          value: "canChangeSecondaryPoints",
          class: "nowrap",
        },

        {
          text: "Permitir Push Notifications",
          value: "allowNotifications",
          class: "nowrap",
        },
        ,
        // {
        //   text: "Cambiar punto final",
        //   value: "canChangeDestinationPoint",
        //   class: "nowrap",
        // },

        // {
        //   text: "¿Puede elegir cualquier punto?",
        //   value: "canChangePoints",
        //   class: "nowrap",
        // },

        // {
        //   text: "Agregar paradas intermedias",
        //   value: "canAddStops",
        //   class: "nowrap",
        // },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          text: "Creado",
          value: "createdAt",
          width: "180px",
          class: "nowrap",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  computed: {},
  methods: {
    ...mapActions(["addCorporative", "addBusiness"]),

    add() {
      this.addDialog = true;
    },
    handleSuccess() {
      this.snackbarText = "Comercio agregado exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
    },

    edit(item) {
      this.addCorporative(item);
      this.snackbarText = "Comercio agregado al menú";
      this.snackbar = true;
      this.addBusiness(null)

      setTimeout(() => {
        document.getElementById("cargoMenu").click();

        this.scroll();
      }, 500);
    },
    scroll() {
      setTimeout(() => {
        let ele = document.getElementsByClassName(
          "v-navigation-drawer__content"
        );

        ele[0].scroll({ top: ele[0].scrollHeight, behavior: "smooth" });
      }, 500);
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        })
        .catch((err) => console.error("Async: Could not copy text: ", err));
    },
    switchControlChanged(data, businessId, business, field) {
      if (data && businessId) {
        this.loading = true;
        db.collection("businessesCorporative")
          .doc(businessId)
          .update(data)
          .then(() => {
            this.loading = false;
            this.snackbarText =
              "Información del comercio actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            business[field] = !business[field];
          });
      }
    },
  },
  async mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "comercio";
    this.$store.state.search = "";

    await this.$binding(
      "businesses",
      db.collection("businessesCorporative").where("deleted", "==", false)
    );
    this.loading = false;
  },
};
</script>
